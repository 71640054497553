<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            src="../assets/login.jpg"
            :aspect-ratio="aspectRatio"
            class="darkened-image"
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xs="12" md="6">
          <p class="text-h6 text-sm-h4 font-weight-medium">
            Access Your Lender Portal
          </p>

          <p class="mt-5 mb-0 text-caption text-sm-body-1">
            Please enter in your login credentials. If you want to become a
            lender, please contact us to get onboarded.
          </p>
        </v-col>
      </v-row>

      <v-form @submit.prevent="submit">
        <v-row justify="center" class="mb-2">
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              :class="windowWidth < 600 ? 'mb-3' : 'mb-7'"
              label="email"
              placeholder="Email"
              outlined
              v-model="email"
              hide-details="auto"
              :dense="windowWidth < 600"
            />

            <v-text-field
              class="mb-5"
              label="password"
              placeholder="password"
              outlined
              v-model="password"
              type="password"
              v-on:keyup.enter="submit"
              hide-details="auto"
              :dense="windowWidth < 600"
            />

            <span class="d-flex align-center flex-wrap">
              <v-checkbox
                v-model="isRemembered"
                label="Remember me"
                class="mr-2"
                :dense="windowWidth < 600"
              />
              <span class="grey--text text-caption text-sm-body-2">
                (Do not use on public computers, you will stay logged in)
              </span>
            </span>

            <br />
            <!-- <span style="cursor: pointer; font-size: 14px; position: absolute; margin-top: -20px"  @click="$router.push({path: `/myAccount/reset-password`})">Forgot Pasword</span> -->

            <p v-if="sending && !errors" class="text-caption text-sm-body-2">Logging in...</p>

            <v-alert v-if="errors.length" type="error">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </v-alert>

            <v-btn @click="submit" :small="windowWidth < 600"> Login </v-btn>

            <span
              style="cursor: pointer"
              class="text-caption text-sm-body-2 ml-5 forgot-password"
              @click="$router.push({ path: `/myAccount/reset-password` })"
            >
              Forgot Pasword
            </span>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  data() {
    return {
      email: "",
      password: "",
      hideText: false,
      sending: false,
      isRemembered: false,
      errors: [],

      windowWidth: window.innerWidth,
    };
  },
  methods: {
    async submit() {
      try {
        this.errors = [];
        this.sending = true;
        let loginResult = await API().post("/api/auth/local", {
          identifier: this.email,
          password: this.password,
        });
        console.log("loginResult is:");
        console.log(loginResult);

        if (loginResult.status === 200) {
          await this.$store.dispatch("Auth/jwt_login", loginResult.data);

          this.$router.push("/dashboard/mortgagesAvailable/list");
        } else {
          this.sending = false;
          this.errors.push("There was an error logging in, please try again.");
        }

        if (this.isRemembered) {
          localStorage.setItem("isRemembered", this.isRemembered);
        } else {
          localStorage.setItem("isRemembered", this.isRemembered);
        }
      } catch (error) {
        console.log(error);
        this.errors.push(
          "There was an error logging in, please check your information and try again."
        );
        this.sending = false;
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  computed: {
    aspectRatio() {
      if (this.windowWidth >= 1264) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 2.5;
      } else if (this.windowWidth >= 600) {
        return 2;
      } else {
        return 1.5;
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style></style>
